<template>
    <b-row>
        <b-col :key="JSON.stringify(item)" cols="5">
            <SelectInput
                :model="item.type"
                :name="`jewelryType${index}`"
                :options="jewelryItemOptions"
                :title="index === 0 ? 'Jewelry Type' : ''"
                :show-error="showError"
                :validator="v => !!v"
                error-label="Jewelry Type is required."
                @inputChanged="setJewelryType"
                @validate="val => setValid('type', val)"
            />
        </b-col>
        <b-col cols="5">
            <TextInput
                :formatter="priceFormatter"
                :model="item.value"
                :name="`jewelryValue${index}`"
                :title="index === 0 ? 'Value' : ''"
                :validator="v => !!v"
                :show-error="showError"
                :tooltip="
                    index === 0
                        ? 'The value of the jewelry item listed on a recent detailed receipt or appraisal. This value will be the most we will pay for repair or replacement in the event of a jewelry damage or loss.'
                        : ''
                "
                max="10000000"
                min="1"
                type="number"
                error-label="Jewelry Value is required."
                @inputChanged="val => (item.value = val)"
                @validate="val => setValid('value', val)"
            />
        </b-col>
        <b-col cols="2">
            <b-icon class="h4 remove-icon" icon="x-circle" @click="remove(index)" />
        </b-col>
    </b-row>
</template>

<script>
import SelectInput from '@/components/FormGroups/SelectInput';
import TextInput from '@/components/FormGroups/TextInput';
import constants from '@/constants';

export default {
    name: 'JewelryItem',
    components: { TextInput, SelectInput },
    props: {
        item: {
            type: Object,
            required: true
        },
        index: {
            type: Number,
            required: true
        },
        remove: {
            type: Function,
            required: true
        },
        showError: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        jewelryItemOptions: constants.jewelryItems,
        valid: {
            type: false,
            value: false
        }
    }),
    methods: {
        setJewelryType(val) {
            this.item.type = val;
            this.item.name = this.jewelryItemOptions.find(item => item.value === val).text;
        },
        setValid(field, value) {
            this.valid[field] = value;
            this.$emit('validate', !Object.keys(this.valid).find(f => !this.valid[f]));
        },
        priceFormatter(value) {
            if (value) {
                return value < 1 ? 1 : value;
            }

            return '1';
        }
    }
};
</script>

<style scoped>
.row {
    margin: 0 -10px;
    align-items: center;
    width: 100%;
}

.remove-icon {
    cursor: pointer;
    position: relative;
    top: 15px;
    color: #606062;
}
</style>
