import actionTypes from '@/constants/actionTypes';
import jewelryItems from '@/constants/jewelryItems';
import states from '@/constants/states.json';
import monthNames from '@/constants/monthNames';
import relationshipsToApplicant from '@/constants/relationshipsToApplicant';
import crimeItems from '@/constants/crimeItems';
import lossItems from '@/constants/lossItems';

export default {
    storageKey: 'vuex',
    minimumPolicyPremiumPrice: 30,
    actionTypes,
    jewelryItems,
    states,
    monthNames,
    relationshipsToApplicant,
    crimeItems,
    lossItems,
    steps: {
        initial: 1,
        quote: 2,
        applicantAndWearers: 3,
        jewelryDetails: 4,
        review: 5,
        payment: 6,
        confirmation: 7
    },
    genders: [
        { text: 'Male', value: 'Male' },
        { text: 'Female', value: 'Female' }
    ],
    yesNoItems: [
        { text: 'No', value: 'no' },
        { text: 'Yes', value: 'yes' }
    ],
    wearerSomeoneElseValue: 'someoneElse',
    wearerApplicantValue: 'primary'
};
