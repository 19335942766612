<template>
    <div>
        <b-modal ref="modal" title="Invalid items">
            <template #modal-footer="{ ok, cancel }">
                <div class="footer-buttons">
                    <Button type="button" text="Ok" cancel @clickEvent="cancel()" />
                </div>
            </template>

            <b-row v-if="allItemsInvalid">
                <b-col>
                    Sorry, the items may not be added at this time. To continue, please call
                    <b-link href="tel:888-346-9464">888-346-9464</b-link>
                    .
                </b-col>
            </b-row>
            <b-row v-for="(item, i) in invalidItems" :key="i" v-else>
                <b-col>
                    Sorry, the recently added item with a value of
                    <DecimalPrice :price="item.value" />
                    may not be added at this time. Please continue to apply without this item. It may be added later by
                    calling
                    <b-link href="tel:888-346-9464">888-346-9464</b-link>
                    .
                </b-col>
            </b-row>
        </b-modal>
    </div>
</template>

<script>
import Button from '@/components/FormGroups/Button';
import DecimalPrice from '@/components/Common/DecimalPrice';

export default {
    name: 'InvalidItemsModal',
    components: { Button, DecimalPrice },
    props: {
        invalidItems: {
            type: Array,
            required: true
        },
        allItemsInvalid: {
            type: Boolean,
            required: true
        }
    },
    methods: {
        show() {
            this.$refs.modal.show();
        }
    }
};
</script>

<style lang="scss" scoped>
.footer-buttons {
    width: 100%;
    .btn {
        margin-right: 1em;
    }
}
</style>
