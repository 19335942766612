export default {
    buttons: [
        { text: 'No', value: 'no', showForm: false },
        { text: 'Misdemeanor', value: 'misdemeanor', showForm: true },
        { text: 'Felony', value: 'felony', showForm: true },
        {
            text: 'Misdemeanor & Felony',
            value: 'misdemeanor-felony',
            showForm: true
        }
    ],
    typeOfConviction: [
        { text: 'Assault', value: 1 },
        { text: 'DUI/OWI', value: 2 },
        { text: 'Drugs', value: 3 },
        { text: 'Fraud', value: 4 },
        { text: 'Homicide', value: 5 },
        { text: 'Robbery/Burglary', value: 6 },
        { text: 'Theft', value: 7 },
        { text: 'Other', value: 8 }
    ],
    toEnum: id => {
        switch (id) {
            case 1:
                return 'Assault';
            case 2:
                return 'DUIOWI';
            case 3:
                return 'Drugs';
            case 4:
                return 'Fraud';
            case 5:
                return 'Homicide';
            case 6:
                return 'RobberyBurglary';
            case 7:
                return 'Theft';
            default:
                return 'Other';
        }
    }
};
