import cloneDeep from 'lodash/cloneDeep';
import constants from '@/constants';
import classes from '@/classes';

const getState = () => ({
    resultStep: constants.steps.initial,

    // Data related to the zip code the user enters on the first step
    previewQuoteLocation: {
        zipCode: '',
        city: null,
        state: null,
        county: null,
        country: null
    },

    // Only available if the user entered the flow with a valid appraisal number
    appraisal: null,

    // The list of jewelry items the user entered in the first step, or from an appraisal
    jewelryItems: [cloneDeep(classes.jewelryItem)],

    // The final quote, using additional risk modifiers
    rateQuote: null,

    // GW account information - only available once the user submits a policy/application request
    account: null,

    // Payment details, if available
    payment: null,

    isLoadingQuotes: false,
    applicantAndWearers: null // If null, show What You'll Need page.
});

export default getState;
