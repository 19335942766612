<template>
    <b-row>
        <Left>
            <InitialStep />
        </Left>
        <Right>
            <Coverage />
        </Right>
    </b-row>
</template>

<script>
import Left from '@/components/Common/Left';
import Right from '@/components/Common/Right';
import InitialStep from '@/components/InitialStep';
import Coverage from '@/components/Coverage';
export default {
    name: 'Home',
    components: {
        Coverage,
        InitialStep,
        Right,
        Left
    },
    created() {}
};
</script>

<style scoped></style>
