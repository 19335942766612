<template>
    <footer class="footer">
        <b-container class="footer-container" fluid="md">
            <b-row>
                <b-col cols="12" md="8">
                    <p>&copy;&nbsp;{{ new Date().getFullYear() }}&nbsp;Jewelers Mutual Group. All Rights Reserved.</p>
                    <p>
                        Coverage is subject to underwriting review and approval, and to the actual policy terms and
                        conditions. Any descriptions are a brief summary of coverage and are not part of any policies,
                        nor a substitute for the actual policy language.
                    </p>
                    <p>
                        Coverage is offered by a member insurer of the Jewelers Mutual Group, either Jewelers Mutual
                        Insurance Company, SI (a stock insurer) or JM Specialty Insurance Company. Policyholders of both
                        insurers are members of Jewelers Mutual Holding Company.
                    </p>
                </b-col>
                <b-col class="d-flex justify-content-between" cols="7" md="4">
                    <div>
                        <b-link href="/privacy-notice" target="_blank">
                            Privacy Notice
                        </b-link>
                    </div>
                    <div>
                        <b-link class="ml-auto" href="https://www.jewelersmutual.com/terms-of-use" target="_blank">
                            Terms of Use
                        </b-link>
                    </div>
                </b-col>
            </b-row>
        </b-container>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
};
</script>

<style lang="scss" scoped>
.footer {
    margin-top: $space-between-main-elements;
    background-color: #f3f3f3;
}

.footer-container {
    padding: 40px 15px;
    font-size: 12px;

    a {
        color: #000;
    }
}
</style>
