<template>
    <span>&#36;{{ displayPrice | numeral('0,0.00') }}</span>
</template>

<script>
export default {
    name: 'DecimalPrice',
    props: {
        price: {
            type: [String, Number],
            required: true,
            default: '0'
        },
        round: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    computed: {
        displayPrice() {
            if (this.round) {
                return Math.round(this.price);
            }
            return this.price;
        }
    }
};
</script>

<style scoped></style>
