export default {
    name: '',
    type: '',
    value: '',
    hasAppraisal: false,
    appraisalId: null,
    quotes: [],
    insuredPerson: null,
    subType: '',
    serialNumber: '',
    brand: '',
    gender: '',
    description: '',
    dateOfPurchase: '',
    file: '',
    itemId: null
};
