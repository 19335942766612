<template>
    <div>
        <TitleForRetrieveLink />
        <b-form v-if="form" @submit.prevent="submit">
            <!--   QUESTIONS ARE DYNAMIC BUT WE DO NOT KNOW HOW THE ENDPOINT RESPONSE LOOKS LIKE.
         FOR NOW, WE WILL KEEP THESE FIXED QUESTIONS. -->
            <Panel>
                <b-form-row>
                    <b-col class="question-title" cols="12">
                        1. Enter the Zip/Postal Code of the Jewelry Wearer
                    </b-col>
                    <b-col cols="5">
                        <TextInput
                            :model="form.zipCode"
                            :show-error="showAllErrors"
                            :validator="zipFieldValidator"
                            :error-label="zipErrorLabel"
                            name="zipcode"
                            @inputChanged="val => (form.zipCode = val)"
                            @blur="validateZipCode"
                            @validate="val => (valid.zipCode = val)"
                        />
                    </b-col>
                    <b-col cols="1" v-if="loadingZip">
                        <LoadingSpinner />
                    </b-col>
                    <b-col cols="1" v-else-if="!!form.county">
                        <b-icon class="county-success" icon="check-circle-fill" />
                    </b-col>
                    <b-col cols="1" v-else-if="zipError">
                        <b-icon class="county-error" icon="x-circle" />
                    </b-col>
                </b-form-row>
                <b-form-row v-if="showCountyForm && availableCounties.length">
                    <RadioButton
                        :model="form.county"
                        :options="availableCounties"
                        :validator="v => !!v"
                        :show-error="showAllErrors"
                        title="County"
                        name="county"
                        @inputChanged="val => (form.county = val)"
                        @validate="val => (valid.county = val)"
                    />
                </b-form-row>
                <b-form-row v-else-if="showCountyForm">
                    <b-col cols="5">
                        <TextInput
                            :model="form.county"
                            :show-error="showAllErrors"
                            :validator="v => !!v"
                            title="County"
                            name="county"
                            @inputChanged="val => (form.county = val)"
                            @validate="val => (valid.county = val)"
                        />
                    </b-col>
                </b-form-row>
            </Panel>
            <Panel>
                <JewelryItems
                    :jewelries="jewelryForm"
                    :show-errors="showAllErrors"
                    @inputChanged="val => (this.jewelryForm = val)"
                    @validate="val => (valid.jewelryItems = val)"
                />
            </Panel>
            <b-form-row class="button-row">
                <Button text="Show my quote" type="submit" />
            </b-form-row>
        </b-form>

        <InvalidItemsModal :invalid-items="invalidItems" :all-items-invalid="allItemsInvalid" ref="invalidItemsModal" />
    </div>
</template>

<script>
import axios from 'axios';
import cloneDeep from 'lodash/cloneDeep';
import classes from '@/classes';
import jewelryValueRules from '@/utils/jewelry-value-rules';
import LoadingSpinner from '@/components/Common/LoadingSpinner';
import Panel from '@/components/Common/Panel';
import TextInput from '@/components/FormGroups/TextInput';
import RadioButton from '@/components/FormGroups/RadioButton';
import Button from '@/components/FormGroups/Button';
import JewelryItems from '@/components/ApplicantAndWearer/JewelryItems';
import TitleForRetrieveLink from '@/components/TitleForRetrieveLink';
import InvalidItemsModal from '@/components/Common/InvalidItemsModal';
import { mapActions, mapGetters } from 'vuex';
import { scrollToError } from '@/utils';
import { isValidCaZipCode, isValidUsZipCode } from '@/validationRules';

export default {
    name: 'InitialStep',
    components: {
        TitleForRetrieveLink,
        JewelryItems,
        TextInput,
        RadioButton,
        Panel,
        Button,
        LoadingSpinner,
        InvalidItemsModal
    },
    data: () => ({
        form: null,
        jewelryForm: [cloneDeep(classes.jewelryItem)],
        valid: {
            zipCode: false,
            county: false,
            jewelryItems: false
        },
        loadingZip: false,
        showAllErrors: false,
        zipError: false,
        invalidItems: [],
        allItemsInvalid: false,
        showCountyForm: false,
        availableCounties: []
    }),
    computed: {
        ...mapGetters(['previewQuoteLocation', 'jewelryItems', 'appraisal', 'order']),
        zipErrorLabel() {
            if (this.form.zipCode) {
                return 'Please enter a valid zip/postal code.';
            }
            return 'Zip/Postal Code is required.';
        }
    },
    created() {
        if (!this.order && !this.appraisal) {
            this.$router.push('look-up');
        }
    },
    mounted() {
        this.form = this.previewQuoteLocation;
        if (this.jewelryItems && this.jewelryItems.length) {
            this.jewelryForm = this.jewelryItems;
        }
        this.valid.zipCode = !!this.form && !!this.form.zipCode;
        this.valid.county = !!this.form && !!this.form.county;
        this.valid.jewelryItems =
            !!this.jewelryForm && !!this.jewelryForm.length && !this.jewelryForm.find(i => !i.type || !i.value);

        if (this.form.zipCode && !this.form.county) {
            this.validateZipCode();
        }
    },
    watch: {
        previewQuoteLocation: {
            deep: true,
            handler: function(val) {
                this.form = val;
            }
        },
        jewelryItems: {
            deep: true,
            handler: function(val) {
                this.jewelryForm = val;
            }
        }
    },
    methods: {
        ...mapActions(['setPreviewQuoteLocation', 'setJewelryItems']),
        isValidZipCode(v) {
            return v && (isValidUsZipCode(v) || isValidCaZipCode(v));
        },
        resetZipData() {
            this.form.state = this.form.county = this.form.city = this.form.country = null;
            this.valid.zipCode = this.valid.county = false;
        },
        zipFieldValidator(v) {
            return !this.zipError && this.isValidZipCode(v);
        },
        async validateZipCode() {
            if (!this.isValidZipCode(this.form.zipCode)) {
                this.resetZipData();
                return;
            }

            try {
                this.loadingZip = true;

                const { data } = await axios(`/api/address/zip/${this.form.zipCode}`);
                if (!data.country || !data.state || !data.cities || !data.cities.length || !data.counties) {
                    this.zipError = true;
                    this.resetZipData();
                } else {
                    this.zipError = false;
                    this.valid.zipCode = true;

                    this.form.state = data.state;
                    this.form.country = data.country;
                    this.form.city = data.cities[0];

                    if (!data.counties || !data.counties.length) {
                        this.availableCounties = [];
                        this.showCountyForm = true;
                        this.form.county = '';
                        this.valid.county = false;
                    } else if (data.counties.length === 1) {
                        this.availableCounties = [];
                        this.showCountyForm = false;
                        this.form.county = data.counties[0];
                        this.valid.county = true;
                    } else {
                        this.availableCounties = data.counties.map(i => {
                            return { text: i, value: i };
                        });
                        this.showCountyForm = true;
                        this.form.county = '';
                        this.valid.county = false;
                    }
                }
            } catch (e) {
                this.zipError = true;
                this.resetZipData();
            }
            this.loadingZip = false;
        },
        async submit() {
            if (Object.keys(this.valid).find(f => !this.valid[f])) {
                this.$nextTick(() => scrollToError(this.$refs.form));
                this.showAllErrors = true;
                return;
            }

            const underwritingRulesValid = jewelryValueRules(this.jewelryForm, this.previewQuoteLocation.country);
            this.invalidItems = underwritingRulesValid.invalidItems;
            this.allItemsInvalid = underwritingRulesValid.allItemsInvalid;
            console.log(`this.invalidItems:`);
            console.log(this.invalidItems);
            console.log(`this.allItemsInvalid:`);
            console.log(this.allItemsInvalid);
            if (underwritingRulesValid.allItemsInvalid || underwritingRulesValid.invalidItems.length) {
                underwritingRulesValid.invalidItems.map(async item => {
                    const payload = {
                        mode: 'uw_limit_met',
                        eventCategory: 'Underwriting Rules',
                        eventAction: 'Item value limit hit',
                        eventLabel: 'Entry Page',
                        eventValue: item.value,
                        eventJewelryType: item.name
                    };

                    await axios.post(`/api/session/segment`, payload);
                });

                this.$refs.invalidItemsModal.show();
                return;
            }

            this.setPreviewQuoteLocation(this.form);
            this.setJewelryItems(this.jewelryForm);

            this.$router.push('/results');
        }
    }
};
</script>

<style lang="scss" scoped>
.form-row {
    margin: 0;
    align-items: center;
}

.button-row {
    justify-content: flex-end;
}

.county-success {
    font-size: 1.5em;
    color: $green-success;
}
.county-error {
    font-size: 1.5em;
    color: red;
}
</style>
