<template>
    <b-form-group :description="description" :label-for="`form-input-${name}`" class="font-weight-bold" label-size="sm">
        <template v-slot:label>
            {{ title }}
            <template v-if="!!tooltip">
                <b-icon :id="`show-tooltip-${name}`" class="bootstrap-icon" icon="question-circle-fill" />
                <Tooltip :target="`show-tooltip-${name}`" :title="tooltip" />
            </template>
        </template>
        <b-form-textarea
            v-if="textarea"
            v-model="value"
            :formatter="formatter"
            :name="name"
            :placeholder="placeholder"
            :required="required"
            :state="fieldState"
            lazy-formatter
            rows="3"
            trim
            type="text"
        ></b-form-textarea>
        <b-form-input
            v-else
            v-model="value"
            :formatter="formatter"
            :max="max"
            :min="min"
            :name="name"
            :autocomplete="autocomplete"
            :placeholder="placeholder"
            :required="required"
            :type="type"
            :state="fieldState"
            lazy-formatter
            trim
            @blur="blurHandler"
        />
        <b-form-invalid-feedback v-if="fieldState === false" class="form-control-error">
            {{ getErrorLabel }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
import Tooltip from '@/components/Common/Tooltip';

export default {
    name: 'TextInput',
    components: { Tooltip },
    props: {
        name: {
            type: String,
            required: true
        },
        model: {
            type: [String, Number],
            required: true,
            default: ''
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        tooltip: {
            type: String,
            required: false,
            default: ''
        },
        textarea: {
            type: Boolean,
            required: false,
            default: false
        },
        type: {
            type: String,
            required: false,
            default: 'text',
            validator: value =>
                [
                    'text',
                    'number',
                    'email',
                    'password',
                    'search',
                    'url',
                    'tel',
                    'date',
                    'time',
                    'range',
                    'color'
                ].includes(value)
        },
        min: {
            type: [String, Number],
            required: false
        },
        max: {
            type: [String, Number],
            required: false
        },
        autocomplete: {
            type: String,
            required: false
        },
        formatter: {
            type: Function,
            required: false,
            default: value => value
        },
        errorLabel: {
            type: String,
            required: false
        },
        validator: {
            type: Function,
            required: false,
            default: () => true
        },
        showError: {
            type: Boolean,
            required: false,
            default: false
        },
        onBlurChange: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        value: '',
        userBlurred: false
    }),
    computed: {
        isValid() {
            return !!this.validator(this.value);
        },
        displayError() {
            return this.userBlurred || this.showError;
        },
        fieldState() {
            return !this.displayError || this.isValid ? null : false;
        },
        getErrorLabel() {
            return this.errorLabel || `${this.title || 'This field'} is required`;
        }
    },
    mounted() {
        this.value = this.model;
    },
    watch: {
        value(val) {
            if (!this.onBlurChange) {
                this.sendValue(val);
            }
        },
        model(val) {
            this.value = val;
        }
    },
    methods: {
        blurHandler() {
            this.userBlurred = true;
            if (this.onBlurChange) {
                this.sendValue(this.value);
            }
            this.$emit('blur', { value: this.value });
        },
        sendValue(val) {
            this.$emit('inputChanged', val);
            this.$emit('validate', this.isValid);
        }
    }
};
</script>

<style lang="scss" scoped>
.form-control {
    border-radius: 0;

    &.is-invalid {
        border-color: #f04033;
        background-color: #ffe1e4;

        &:focus {
            background-color: #ffffff;
        }
    }
}
</style>
