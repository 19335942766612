export default [
    {
        title: 'Loss',
        content:
            'You left your ring somewhere—on a beach towel or in a public bathroom. It’s out there somewhere and you can’t find it. It’s covered.',
        color: '#d415cc'
    },
    {
        title: 'Theft',
        content:
            'We all know that feeling of dread when something we cherish is stolen. But you can rest assured that your stolen piece is covered, too.',
        color: '#fbab18'
    },
    {
        title: 'Damage',
        content:
            "You hit your diamond ring on the edge of a table and it cracks, or you're pulling on a sweater and a prong is bent. Accidents happen. We'll cover that.",
        color: '#00b185'
    },
    {
        title: 'Disappearance',
        content:
            'Sometimes jewelry just disappears—it could be lost, stolen or hanging out in a drainpipe somewhere. You may not be able to explain how, where or when it vanished. Don’t worry, that’s covered.',
        color: '#095990'
    },
    {
        title: 'Worldwide Travel'
    }
];
