import jewelryItem from '@/classes/jewelryItem';
import jewelryItemNewWearer from '@/classes/jewelryItemNewWearer';
import jewelryItemForm from '@/classes/jewelryItemForm';
import applicantForm from '@/classes/applicantForm';
import wearerCrime from '@/classes/wearerCrime';

export default {
    jewelryItem,
    jewelryItemNewWearer,
    jewelryItemForm,
    applicantForm,
    wearerCrime
};
