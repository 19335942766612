export default {
    name: '',
    price: '',
    wearer: {
        text: '',
        value: '',
        showForm: false,
        isMailingAddress: true,
        firstName: '',
        lastName: '',
        mailingAddress: {
            address: '',
            apartmentSuite: '',
            city: '',
            state: '',
            zipCode: '',
            county: '',
            country: ''
        },
        phoneNumber: '',
        emailAddress: '',
        dateOfBirth: '',
        relationshipToApplicant: '',
        gender: ''
    }
};
