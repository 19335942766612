<template>
    <div class="panel">
        <slot />
    </div>
</template>

<script>
export default {
    name: 'Panel'
};
</script>

<style scoped lang="scss">
.panel {
    margin-bottom: $margin-under-element;
    border-radius: 0;
    box-shadow: none;
    box-sizing: border-box;

    @media (max-width: 767px) {
        border: 2px solid #ebebeb;
        padding: 10px;
    }

    @media (min-width: 768px) {
        border-bottom: 2px solid #ebebeb;
        padding: 10px 0;
    }
}
</style>
