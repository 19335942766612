const items = [
    { text: 'Damage to Jewelry', value: 2 },
    { text: 'Lost', value: 1 },
    { text: 'Theft', value: 3 }
];

export default items;

export const lossTypeToEnum = id => {
    switch (id) {
        case 2:
            return 'DamageToJewelry';
        case 3:
            return 'Theft';
        default:
            return 'Lost';
    }
};
