import cloneDeep from 'lodash/cloneDeep';
import classes from '@/classes';
import constants from '@/constants';

export default {
    resultStep: state => state.resultStep,
    previewQuoteLocation: state => state.previewQuoteLocation,
    jewelryItems: state => state.jewelryItems,
    account: state => state.account,
    payment: state => state.payment,
    rateQuote: state => state.rateQuote,
    appraisal: state => state.appraisal,
    order: state => state.order,
    isLoadingQuotes: state => state.isLoadingQuotes,
    previewTotals: state =>
        (state.jewelryItems || []).reduce(
            (totals, item) => {
                if (item.quotes && item.quotes.length) {
                    const selectedQuote = item.quotes.find(q => q.selected) || item.quotes[0];
                    totals.itemTotal += selectedQuote.AnnualPremium;
                    totals.itemTax += selectedQuote.TotalTaxesAndSurcharges;
                    if (selectedQuote.MinimumPremium && selectedQuote.MinimumPremium.MinimumPremiumAdjustment) {
                        totals.minimum =
                            selectedQuote.MinimumPremium.MinimumPremiumAdjustment + selectedQuote.AnnualPremium;
                        totals.minimumTax = selectedQuote.TotalAnnualTaxesAndSurcharges;
                    }
                    totals.minimumEnforced = totals.itemTotal < totals.minimum;
                    totals.total = totals.minimumEnforced ? totals.minimum : totals.itemTotal;
                    totals.taxesAndFees = totals.minimumEnforced ? totals.minimumTax : totals.itemTax;
                }
                return totals;
            },
            { total: 0, taxesAndFees: 0, itemTotal: 0, itemTax: 0, minimum: 0, minimumTax: 0, minimumEnforced: false }
        ),
    rateTotals: state => {
        if (!state.rateQuote) {
            return {
                total: 0,
                taxesAndFees: 0,
                discount: 0,
                minimumEnforced: false
            };
        }
        const totals = {
            total: state.rateQuote.TotalAnnualPremium,
            taxesAndFees: state.rateQuote.TotalAnnualTaxesAndSurcharges,
            discount: 0,
            minimumEnforced: 0
        };
        if (
            state.rateQuote &&
            state.rateQuote.MinimumPremium &&
            state.rateQuote.MinimumPremium.MinimumPremiumAdjustment
        ) {
            totals.minimumEnforced = true;
            return totals;
        }
        return (state.rateQuote && state.rateQuote.ResponseItems ? state.rateQuote.ResponseItems : []).reduce(
            (totals, item) => {
                totals.discount += -1 * (item.TotalRiskModifierAdjustment || 0);
                return totals;
            },
            totals
        );
    },
    isTotalLowerThanMinimumPolicyPremium: state =>
        state.jewelryItems.length === 1 && state.previewTotals.total <= constants.minimumPolicyPremiumPrice,
    hasAllAppraisals: state => {
        if (!state.jewelryItems || !state.jewelryItems.length) {
            return false;
        }
        return state.jewelryItems.findIndex(item => !item.hasAppraisal) === -1;
    },
    getApplicant: ({ applicantAndWearers }) => {
        if (applicantAndWearers) {
            return applicantAndWearers.applicant;
        }
        return null;
    },
    applicantAndWearers: state => state.applicantAndWearers,
    getApplicantAndWearers: ({ applicantAndWearers }) => {
        if (!applicantAndWearers) {
            return null;
        }
        const { wearerJewelryItems } = applicantAndWearers;

        const countInItems = value => wearerJewelryItems.filter(item => item.wearerValue === value);

        const possibleApplicants = [];

        wearerJewelryItems.forEach(jewelry => {
            const { firstName, lastName } = jewelry.wearer;

            if (firstName) {
                const value = `${firstName}_${lastName || ''}`;
                const wearer = jewelry.wearer;

                const filteredItemsByWearerValue = countInItems(value);

                if (filteredItemsByWearerValue.length > 1) {
                    filteredItemsByWearerValue.find(({ wearer }) => wearer.firstName).wearer.showForm = true;
                } else if (filteredItemsByWearerValue.length <= 1) {
                    wearer.showForm = true;
                }
                possibleApplicants.push({
                    ...wearer,
                    text: `${firstName} ${lastName || ''}`,
                    value
                });

                jewelry.wearerValue = value;
            }
        });

        possibleApplicants.unshift({
            text: 'Primary Applicant',
            value: constants.wearerApplicantValue,
            showForm: false
        });

        possibleApplicants.push({
            text: 'Someone else',
            value: constants.wearerSomeoneElseValue,
            showForm: true,
            ...cloneDeep(classes.jewelryItemNewWearer)
        });

        return possibleApplicants;
    }
};
