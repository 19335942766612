<template>
    <b-col class="container" cols="12" md="8">
        <slot />
    </b-col>
</template>

<script>
export default {
    name: 'Left'
};
</script>

<style lang="scss" scoped>
.container {
    margin-bottom: $margin-under-element - 10px;
}
</style>
