<template>
    <img class="loading-spinner-img" alt="spinner" :src="require('@/assets/spinner-black.png')" />
</template>

<script>
export default {
    name: 'LoadingSpinner'
};
</script>

<style lang="scss" scoped>
.loading-spinner-img {
    animation: loading-spin 1000ms infinite linear;
}
@keyframes loading-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}
</style>
