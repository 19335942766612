export default [
    { text: 'Wife', value: 1 },
    { text: 'Husband', value: 2 },
    { text: 'Fiancee', value: 3 },
    { text: 'Boyfriend', value: 4 },
    { text: 'Girlfriend', value: 5 },
    { text: 'Partner', value: 6 },
    { text: 'Parent', value: 7 },
    { text: 'Child', value: 8 },
    { text: 'Other', value: 9 }
];
