import constants from '@/constants';
import router from '@/router';
import get from 'lodash/get';

export default {
    setResultStep: (state, value) => (state.resultStep = value),
    setPreviewQuoteLocation: (state, previewQuoteLocation) => {
        state.previewQuoteLocation = previewQuoteLocation;
        //when previewQuote location changes we need to update applicant information as well.

        const existingApplicant = get(state, 'applicantAndWearers.applicant', null);
        if (existingApplicant) {
            const applicant = {
                zipCode: previewQuoteLocation.zipCode,
                city: previewQuoteLocation.city,
                country: previewQuoteLocation.country,
                county: previewQuoteLocation.county,
                state: previewQuoteLocation.state
            };

            state.applicantAndWearers.applicant = {
                ...existingApplicant,
                ...applicant
            };
        }
    },
    setAppraisal: (state, value) => {
        state.appraisal = value;
        state.order = undefined;
    },
    setOrder: (state, value) => {
        state.order = value;
        state.appraisal = undefined;
    },
    setJewelryItems: (state, value) => (state.jewelryItems = value),
    setAccount: (state, value) => (state.account = value),
    setPayment: (state, value) => (state.payment = value),
    setRateQuote: (state, value) => (state.rateQuote = value),
    setLoadingQuotes: (state, isLoading) => (state.isLoadingQuotes = isLoading),
    addNewJewelryItemsInInitialStep(state, jewelryItems) {
        jewelryItems.forEach(newItem => {
            if (newItem.selected) {
                newItem.type = newItem.value;
                newItem.value = '';

                state.jewelryItems.push(newItem);
            }
        });
    },
    setApplicantAndWearers(state, applicantAndWearers) {
        state.previewQuoteLocation.zipCode = applicantAndWearers.applicant.zipCode;
        state.applicantAndWearers = applicantAndWearers;
    },
    setApplicant(state, applicant) {
        state.applicantAndWearers = {
            ...state.applicantAndWearers,
            applicant
        };
    },
    setSession(state, data) {
        /* eslint-disable prettier/prettier */
        ['resultStep', 'appraisal', 'previewQuoteLocation', 'jewelryItems', 'applicantAndWearers', 'rateQuote'].forEach(
            key => {
                state[key] = data[key];
            }
        );
        /* eslint-enable prettier/prettier */

        switch (state.resultStep) {
            case constants.steps.quote:
                router.push('/results');
                break;
            case constants.steps.applicantAndWearers:
                router.push('/applicant');
                break;
            case constants.steps.jewelryDetails:
                router.push('/jewelry-details');
                break;
            case constants.steps.review:
                router.push('/review');
                break;
            default:
                router.push('/');
        }
    }
};
