<template>
    <b-col class="coverage-item-container" cols="6" sm="6" md="12" lg="6">
        <b-icon class="h5 icon" icon="check-circle-fill" />
        <span class="pl-2">{{ text }}</span>
    </b-col>
</template>

<script>
export default {
    name: 'CoverageItem',
    props: {
        text: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.coverage-item-container {
    display: flex;
    min-height: 30px;
    padding-bottom: 5px;
    font-size: 14px;
    font-weight: 700;
    text-transform: capitalize;

    .icon {
        color: $blue;
    }
}
</style>
