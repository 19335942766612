export default {
    value: '',
    sentence: {
        date: '',
        conviction: ''
    },
    loss: {
        value: '',
        lostItems: [
            {
                date: '',
                type: '',
                amount: ''
            }
        ]
    }
};
