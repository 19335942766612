<template>
    <b-form-row align-v="center">
        <b-col class="question-title" cols="12">
            2. Tell Us About Your Jewelry Item(s)
        </b-col>
        <div v-if="!items.length" class="error">
            Please add items to continue.
        </div>
        <template v-for="(item, i) in items">
            <JewelryItem
                :key="i"
                :index="i"
                :item="item"
                :remove="removeItem"
                :show-error="showErrors"
                @validate="val => setValid(i, val)"
            />
        </template>
        <b-col class="add-item" cols="12">
            <span @click="addItem" class="pointer">
                <b-icon class="h5" icon="plus-circle" />
                Add another item
            </span>
        </b-col>
    </b-form-row>
</template>
<script>
import cloneDeep from 'lodash/cloneDeep';
import classes from '@/classes';
import JewelryItem from '@/components/ApplicantAndWearer/JewelryItem';
import axios from 'axios';

export default {
    name: 'JewelryItems',
    components: { JewelryItem },
    props: {
        jewelries: {
            type: Array,
            required: true
        },
        showErrors: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        items: [],
        valid: []
    }),
    mounted() {
        this.items = this.jewelries;
        this.valid = this.jewelries.map(v => !!v.type && !!v.value);
    },
    watch: {
        items: {
            handler: function(val) {
                this.$emit('inputChanged', val);
            },
            deep: true
        },
        jewelries: {
            deep: true,
            handler: function(val) {
                this.items = val;
            }
        }
    },
    methods: {
        pushEvent(val) {
            this.$emit('inputChanged', val || this.items);
        },
        sendValidateEvent() {
            this.$emit('validate', !!this.items.length && this.valid.findIndex(v => !v) === -1);
        },
        removeItem(index) {
            if (this.items.length > 0) {
                this.items.splice(index, 1);
                this.valid.splice(index, 1);

                this.pushEvent();
                this.sendValidateEvent();
            }
        },
        async addItem() {
            this.items.push(cloneDeep(classes.jewelryItem));
            this.valid.push(false);

            this.pushEvent();
            this.sendValidateEvent();

            const payload = {
                mode: 'itemAdded',
                eventCategory: 'Additional Items',
                eventAction: 'Added Item',
                eventValue: this.items.length
            };
            await axios.post(`/api/session/segment`, payload);
        },
        setValid(i, val) {
            this.valid[i] = val;
            this.sendValidateEvent();
        }
    }
};
</script>

<style scoped lang="scss">
.add-item {
    color: $blue;
    font-weight: 700;
    margin-top: $margin-under-element;
    user-select: none;

    svg {
        vertical-align: top;
    }
}
.error {
    font-size: smaller;
    color: red;
    padding-top: 10px;
    padding-bottom: 10px;
}
</style>
