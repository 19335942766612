import { extend } from 'vee-validate';
import { required } from 'vee-validate/dist/rules';

extend('required', {
    ...required,
    message: 'This field is required'
});

extend('zipcode', value => {
    if (isValidUsZipCode(value)) {
        return true;
    }
    if (isValidCaZipCode(value)) {
        return true;
    }
    return 'This field is not a valid zipCode';
});

export function isValidUsZipCode(v) {
    return !!v.match(/^\d{5}(-\d{4})?$/);
}

export function isValidCaZipCode(v) {
    return !!v.toUpperCase().match(/^[ABCEGHJ-NPRSTVXY]\d[ABCEGHJ-NPRSTV-Z][ -]?\d[ABCEGHJ-NPRSTV-Z]\d$/i);
}

export function isValidZipCode(v) {
    return isValidUsZipCode(v) || isValidCaZipCode(v);
}
