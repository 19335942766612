<template>
    <RightSidePanel title="Coverage includes:">
        <CoverageItem v-for="item in items" :key="item.title" :text="item.title" />
        <b-col class="additional" cols="12">
            <span class="no-select pointer" @click="showModal = true">Additional coverage details</span>
        </b-col>
        <b-modal v-model="showModal" title="Additional Coverage Details">
            <div>
                <div>
                    <h2>What's Covered</h2>
                </div>
                <div>
                    <p>
                        We provide all types of jewelry insurance, including engagement ring insurance. Watches,
                        earrings, and even loose stones while being set - yep, we cover those too.
                    </p>
                    <p>
                        No matter the type of jewelry you insure, it'll be protected by our comprehensive repair or
                        replacement coverage.
                        <span>Plus, your coverage includes worldwide travel.</span>
                    </p>
                </div>
            </div>
            <div>
                <div class="whats-covered-item-container">
                    <h4 class="bold">Loss</h4>
                    <p>
                        You left your ring somewhere—on a beach towel or in a public bathroom. It’s out there somewhere
                        and you can’t find it. It’s covered.
                    </p>
                </div>
                <div class="whats-covered-item-container">
                    <h4 class="bold">Theft</h4>
                    <p>
                        We all know that feeling of dread when something we cherish is stolen. But you can rest assured
                        that your stolen piece is covered, too.
                    </p>
                </div>
                <div class="whats-covered-item-container">
                    <h4 class="bold">Damage</h4>
                    <p>
                        You hit your diamond ring on the edge of a table and it cracks, or you're pulling on a sweater
                        and a prong is bent. Accidents happen. We'll cover that.
                    </p>
                </div>
                <div class="whats-covered-item-container">
                    <h4 class="bold">Disappearance</h4>
                    <p>
                        Sometimes jewelry just disappears—it could be lost, stolen or hanging out in a drainpipe
                        somewhere. You may not be able to explain how, where or when it vanished. Don’t worry, that’s
                        covered.
                    </p>
                </div>
            </div>
            <template #modal-footer="{ ok }">
                <div class="footer-buttons">
                    <Button type="submit" text="Ok" @clickEvent="ok()" />
                </div>
            </template>
        </b-modal>
    </RightSidePanel>
</template>

<script>
import CoverageItem from '@/components/CoverageItem';
import RightSidePanel from '@/components/Common/RightSidePanel';
import coverageOptions from '@/constants/coverageOptions';
import Button from '@/components/FormGroups/Button';
export default {
    name: 'Coverage',
    components: { RightSidePanel, CoverageItem, Button },
    computed: {
        items() {
            return coverageOptions;
        }
    },
    data() {
        return {
            showModal: false
        };
    }
};
</script>

<style lang="scss" scoped>
.additional {
    color: $blue;
    text-decoration: underline;
    font-weight: 700;
    margin-top: $margin-under-element;
}
.footer-buttons {
    width: 100%;
    display: flex;
    justify-content: flex-end;
}
</style>
