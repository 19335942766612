<template>
    <b-overlay :show="isLoading" rounded="sm" class="row right-side-container">
        <template #overlay>
            <div class="text-center">
                <loading-spinner></loading-spinner>
            </div>
        </template>
        <b-col class="title" cols="12">
            <span class="text-capitalize">{{ title }}</span>
        </b-col>
        <slot />
    </b-overlay>
</template>

<script>
import LoadingSpinner from '@/components/Common/LoadingSpinner';
export default {
    name: 'RightSidePanel',
    components: { LoadingSpinner },
    props: {
        title: {
            type: String,
            required: false,
            default: ''
        },
        isLoading: {
            type: Boolean,
            default: false
        }
    }
};
</script>

<style scoped lang="scss">
.right-side-container {
    border-top: 2px solid #0097d6;
    background-color: $white-smoke;
    padding: $margin-under-element 0;
    text-transform: capitalize;

    .title {
        font-size: 16px;
        font-weight: 700;
        padding-bottom: $margin-under-element;
    }

    .additional {
        color: $blue;
        text-decoration: underline;
        font-weight: 700;
        margin-top: $margin-under-element;
    }
}
</style>
