import moment from 'moment';

const US_MAXVALUE_DEFAULT = 100000;
const US_MAXVALUE_ITEMS_SUM = 100000;

const CAN_MAXVALUE_DEFAULT = 5000;
const CAN_MAXVALUE_ITEMS_SUM = 5000;

const validateJewelryItemValue = (item, country) => {
    if (country.includes('US')) {
        return !(item.value && item.value > US_MAXVALUE_DEFAULT);
    } else {
        return !(item.value && item.value > CAN_MAXVALUE_DEFAULT);
    }
};

const validateJewelryItemValues = (items, country = 'US') => {
    return items.reduce(
        (result, item) => {
            if (!validateJewelryItemValue(item, country)) {
                result.invalidItems.push(item);
            }

            // Block items with "Other" type
            if (item.type === 8) {
                result.invalidItems.push(item);
            }
            try {
                result.totalValue += parseInt(item.value);
            } catch (e) {
                // Ignore
            }
            if (items.length > 1 && !result.allItemsInvalid) {
                if (country.includes('US') && result.totalValue > US_MAXVALUE_ITEMS_SUM) {
                    result.allItemsInvalid = true;
                } else if (country.includes('CA') && result.totalValue > CAN_MAXVALUE_ITEMS_SUM) {
                    result.allItemsInvalid = true;
                }
            }
            return result;
        },
        {
            invalidItems: [],
            allItemsInvalid: false,
            totalValue: 0
        }
    );
};

const isCrimeValidated = form => {
    const selectedCrimeOption = form.wearerCrime.value;
    const convictionType = form.wearerCrime.sentence.conviction;
    const convictionDate = form.wearerCrime.sentence.date;
    if (selectedCrimeOption === 'no') {
        return true;
    } else if (selectedCrimeOption === 'misdemeanor' && convictionType === 2) {
        return true;
    } else {
        // any other type needs to be blocked if date is within 7 years.
        return moment() > moment(convictionDate).add(7, 'Y');
    }
};

const isValidateLoss = form => {
    const lossValue = form.wearerCrime.loss.value;
    if (lossValue === 'yes') {
        const lossItems = form.wearerCrime.loss.lostItems;

        if (lossItems.length >= 3) {
            return false;
        }
        for (let i = 0; i < lossItems.length; i++) {
            const value = Number(lossItems[i].amount);
            if (value > 7500) {
                return false;
            }
        }
    }
    return true;
};

export default validateJewelryItemValues;
export { isValidateLoss, isCrimeValidated };
