<template>
    <b-navbar toggleable="sm">
        <b-navbar-brand href="https://www.jewelersmutual.com/" target="_jm-home">
            <img alt="logo" class="d-none d-md-block d-sm-none" src="../../../public/img/logo-big.png" />
            <img
                alt="logo"
                class="d-md-none d-sm-block d-xs"
                height="36"
                src="../../../public/img/logo-small.png"
                width="36"
            />
        </b-navbar-brand>

        <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

        <b-collapse id="nav-collapse" is-nav>
            <b-navbar-nav class="ml-auto">
                <b-nav-text>
                    <span>Questions?</span>
                </b-nav-text>
                <b-nav-item :href="`tel:${tel}`">{{ tel }}</b-nav-item>
                <b-nav-item :href="`mailto:${mail}`">Email</b-nav-item>
                <b-nav-item to="faq">FAQ</b-nav-item>
            </b-navbar-nav>
        </b-collapse>
    </b-navbar>
</template>

<script>
export default {
    name: 'Header',
    data: () => ({
        mail: 'personaljewelry@jminsure.com',
        tel: '888-346-9464'
    })
};
</script>

<style lang="scss" scoped>
.navbar {
    border-bottom: 2px solid $gray92;
    margin-bottom: $space-between-main-elements;
}

.navbar-nav {
    & {
        font-size: 14px;
        font-weight: 700;
    }

    li {
        padding-left: 0.5rem;
        padding-right: 0.5rem;
        color: $black;

        @media (max-width: 575px) {
            padding-left: 0;
            padding-right: 0;
        }
    }
}

.navbar-light .navbar-nav .nav-link,
.navbar-light .navbar-nav .nav-link:hover {
    text-decoration: underline;
    color: $blue;
    font-weight: 700;
}
</style>
