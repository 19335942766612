import axios from 'axios';
import moment from 'moment';
import cloneDeep from 'lodash/cloneDeep';
import _get from 'lodash/get';
import findIndex from 'lodash/findIndex';
import assign from 'lodash/assign';
import groupBy from 'lodash/groupBy';
import filter from 'lodash/filter';
import classes from '@/classes';
import constants from '@/constants';
import { lossTypeToEnum } from '@/constants/lossItems';
import { TYPES as JEWELRY_TYPES, alarmTypeToEnum } from '@/constants/jewelryItems';
import router from '@/router';

const { actionTypes: action } = constants;

const wait = ms =>
    new Promise(resolve => {
        setTimeout(resolve, ms);
    });

function constructAccountInformation({ getters }) {
    const { applicant, wearerCrime, wearerJewelryItems } = getters.applicantAndWearers;

    const request = {
        quoteTrackingId: getters.rateQuote.requestTrackingId,
        effectiveDate: applicant.effectiveDate,
        applicant: {
            firstName: applicant.firstName,
            lastName: applicant.lastName,
            email: applicant.emailAddress,
            phone: applicant.phoneNumber,
            gender: applicant.gender,
            birthDate: moment(applicant.dateOfBirth).format('YYYY-MM-DD'),
            isPaperlessEnrolled: !!applicant.isPaperlessEnrolled,
            isMailingAddressSameAsResidence: applicant.isMailingAddress,
            residenceAddress: {
                addressLine1: applicant.address,
                addressLine2: applicant.addressSuite,
                city: applicant.city,
                state: applicant.state,
                postalCode: applicant.zipCode,
                county: applicant.county,
                country: applicant.country
            },
            source: applicant.source
        },
        underwritingInformation: {
            conviction: {
                convictedOfFelony: wearerCrime.value === 'felony' || wearerCrime.value === 'misdemeanor-felony',
                convictedOfMisdemeanor:
                    wearerCrime.value === 'misdemeanor' || wearerCrime.value === 'misdemeanor-felony'
            },
            lossEvents:
                wearerCrime.loss.value === 'no'
                    ? []
                    : wearerCrime.loss.lostItems.map(item => ({
                          lossAmount: item.amount,
                          lossDate: item.date,
                          lossType: lossTypeToEnum(item.type)
                      })),
            alarmType: alarmTypeToEnum(applicant.security),
            applicantsDeniedOrCancelledCoverage: false
        }
    };

    if (
        request.underwritingInformation.conviction.convictedOfFelony ||
        request.underwritingInformation.conviction.convictedOfMisdemeanor
    ) {
        request.underwritingInformation.conviction.sentenceCompleteDate = wearerCrime.sentence.date;
        request.underwritingInformation.conviction.convictionType = constants.crimeItems.toEnum(
            wearerCrime.sentence.conviction
        );
    }

    if (!applicant.isMailingAddress) {
        request.applicant.mailingAddress = {
            addressLine1: applicant.mailingAddress.address,
            addressLine2: applicant.mailingAddress.addressSuite,
            city: applicant.mailingAddress.city,
            state: applicant.mailingAddress.state,
            postalCode: applicant.mailingAddress.zipCode,
            county: applicant.mailingAddress.county,
            country: applicant.mailingAddress.country
        };
    }

    if (getters.appraisal && getters.appraisal.appraisalNumber) {
        request.appraisalNumber = getters.appraisal.appraisalNumber;
    } else if (getters.order && getters.order.orderNumber) {
        request.orderNumber = getters.order.orderNumber;
    }

    request.jewelryItems = getters.jewelryItems.map((jewelryItem, i) => {
        const item = {
            itemId: jewelryItem.itemId,
            lastAppraisedDate: jewelryItem.dateOfPurchase,
            appraisalId: jewelryItem.appraisalId,
            itemGender: jewelryItem.gender || null,
            itemSubType: jewelryItem.subType || null,
            brand: jewelryItem.brand || null,
            serialNumber: jewelryItem.serialNumber || null,
            description: jewelryItem.description,
            itemType: getJewelryType(jewelryItem.type)
        };

        const wearer = wearerJewelryItems[i];
        if (wearer.wearerValue === 'primary') {
            item.primaryWearer = { ...request.applicant };
        } else {
            const { wearer: wearerInfo } = wearerJewelryItems.find(
                w => w.wearerValue === wearer.wearerValue && w.wearer && w.wearer.showForm
            );
            item.primaryWearer = {
                firstName: wearerInfo.firstName,
                lastName: wearerInfo.lastName,
                email: wearerInfo.emailAddress,
                phone: wearerInfo.phoneNumber,
                gender: wearerInfo.gender,
                birthDate: moment(wearerInfo.dateOfBirth).format('YYYY-MM-DD'),
                relationshipToApplicant:
                    (constants.relationshipsToApplicant.find(r => r.value === wearerInfo.relationshipToApplicant) || {})
                        .text || 'Other'
            };
            if (wearerInfo.isMailingAddress) {
                item.primaryWearer.isMailingAddressSameAsResidence = request.applicant.isMailingAddressSameAsResidence;
                item.primaryWearer.residenceAddress = request.applicant.residenceAddress;
                item.primaryWearer.mailingAddress = request.applicant.mailingAddress;
            } else {
                item.primaryWearer.isMailingAddressSameAsResidence = true;
                item.primaryWearer.residenceAddress = {
                    addressLine1: wearerInfo.mailingAddress.address,
                    addressLine2: wearerInfo.mailingAddress.addressSuite,
                    city: wearerInfo.mailingAddress.city,
                    state: wearerInfo.mailingAddress.state,
                    postalCode: wearerInfo.mailingAddress.zipCode,
                    county: wearerInfo.mailingAddress.county,
                    country: wearerInfo.mailingAddress.country
                };
            }
        }

        return item;
    });
    return request;
}

export default {
    setResultStep({ commit }, value) {
        commit('setResultStep', value);
    },
    setPreviewQuoteLocation({ commit }, value) {
        commit('setPreviewQuoteLocation', value);
    },
    setJewelryItems({ commit }, items) {
        commit('setJewelryItems', items);
    },
    setApplicantAndWearers({ commit }, applicantAndWearers) {
        commit('setApplicantAndWearers', applicantAndWearers);
    },

    addJewelry({ commit, getters }) {
        const updatedItems = getters.jewelryItems;
        updatedItems.push(cloneDeep(classes.jewelryItem));
        commit('setJewelryItems', updatedItems);
    },
    removeJewelry({ commit, getters }, jewelryIndex) {
        const updatedItems = getters.jewelryItems;

        updatedItems.splice(jewelryIndex, 1);
        const redirectToHome = updatedItems.length === 0;
        if (redirectToHome) {
            updatedItems.push(cloneDeep(classes.jewelryItem));
        }
        commit('setJewelryItems', updatedItems);

        const applicantAndWearers = getters.applicantAndWearers;
        if (applicantAndWearers && Array.isArray(applicantAndWearers.wearerJewelryItems)) {
            applicantAndWearers.wearerJewelryItems.splice(jewelryIndex, 1);
            commit('setApplicantAndWearers', applicantAndWearers);
        }

        if (redirectToHome) {
            router.push('/');
        }
    },

    async loadPreviewQuotes({ commit, getters }, updated) {
        const selectedItems = {};
        if (updated) {
            //get currently selected options;
            for (let i = 0; i < getters.jewelryItems.length; i++) {
                const item = getters.jewelryItems[i];
                const index = findIndex(_get(item, 'quotes', []), i => i.selected);
                selectedItems[i] = index < 0 ? 0 : index;
            }
        } else {
            // Clear any existing quotes
            commit(
                'setJewelryItems',
                getters.jewelryItems.map(item => ({
                    ...item,
                    quotes: []
                }))
            );
        }
        const previewQuoteLocation = getters.previewQuoteLocation;
        const commonData = {
            city: previewQuoteLocation.city,
            state: previewQuoteLocation.state,
            county: previewQuoteLocation.county,
            zipCode: previewQuoteLocation.zipCode,
            country: previewQuoteLocation.country,
            streetName: previewQuoteLocation.streetName
        };
        const waitTime = updated ? 0 : 500;
        const promises = getters.jewelryItems.map((item, i) =>
            wait(i * waitTime) // Stagger the requests a little
                .then(() =>
                    axios({
                        url: '/api/quotes/preview',
                        method: 'POST',
                        data: {
                            ...commonData,
                            totalValue: Number(item.value)
                        }
                    })
                )
                .then(({ data }) => {
                    const selected = selectedItems[i] || 0;

                    return data.map((quote, i) => ({
                        ...quote.ResponseItems[0],
                        requestTrackingId: quote.requestTrackingId,
                        MinimumPremium: quote.MinimumPremium,
                        TotalAnnualPremium: quote.TotalAnnualPremium,
                        TotalAnnualTaxesAndSurcharges: quote.TotalAnnualTaxesAndSurcharges,
                        selected: i === selected
                    }));
                })
                .then(quotes => {
                    const items = getters.jewelryItems;
                    items[i].quotes = quotes;
                    commit('setJewelryItems', items);
                })
        );
        return Promise.all(promises);
    },

    async loadRateQuote({ commit, getters }) {
        const applicant = getters.getApplicant;
        const applicantWearers = _get(getters.applicantAndWearers, 'wearerJewelryItems', []);
        const objWears = groupBy(applicantWearers, 'wearerValue');
        const keys = Object.keys(objWears);
        const applicantWearersObj = {};
        for (let i = 0; i < keys.length; i++) {
            const key = keys[i];
            const value = filter(objWears[key], i => _get(i, 'wearer.showForm', false));
            applicantWearersObj[key] = value[0];
        }
        const riskModifiers = [];
        if (applicant.security === 2) {
            riskModifiers.push({
                name: 'Alarm',
                value: 'CentralStation'
            });
        } else if (applicant.security === 3) {
            riskModifiers.push({
                name: 'Alarm',
                value: 'Local'
            });
        } else {
            riskModifiers.push({
                name: 'Alarm',
                value: 'None'
            });
        }

        const requestLocations = [];
        const locationIndex = {};
        const jewelryItems = getters.jewelryItems;
        jewelryItems.forEach((item, i) => {
            // For matching against the rateQuote response items for appraisals. Ids provided by backend for orders
            if (item.itemId == null) {
                item.itemId = i + 1;
            }

            const wearer = getters.applicantAndWearers.wearerJewelryItems[i];
            let wearerId;
            let address;
            if (wearer.wearerValue === 'primary') {
                wearerId = 'primary';
                address = applicant;
            } else if (_get(applicantWearersObj[wearer.wearerValue], 'wearer.isMailingAddress', true) === false) {
                wearerId = wearer.wearerValue;
                address = applicantWearersObj[wearer.wearerValue].wearer.mailingAddress;
            } else {
                wearerId = 'primary';
                address = applicant;
            }
            if (locationIndex[wearerId] === undefined) {
                locationIndex[wearerId] = requestLocations.length;
                requestLocations.push({
                    city: address.city,
                    state: address.state,
                    zipCode: address.zipCode,
                    county: address.county,
                    country: address.country,
                    streetName: address.streetName,
                    items: []
                });
            }

            const selectedQuote = item.quotes.find(j => j.selected);
            requestLocations[locationIndex[wearerId]].items.push({
                limit: Number(item.value),
                deductible: selectedQuote.Deductible,
                riskModifiers,
                itemId: item.itemId
            });
        });

        const quoteRequest = {
            city: applicant.city,
            state: applicant.state,
            zipCode: applicant.zipCode,
            county: applicant.county,
            country: applicant.country,
            effectiveDate: applicant.effectiveDate,
            locations: requestLocations
        };

        const { data } = await axios({
            url: '/api/quotes/rate',
            method: 'POST',
            data: quoteRequest
        });

        if (!data.length || data.length !== 1) {
            throw new Error(`Unexpected response length ${data.length}`);
        }

        const quote = data[0];

        if (quote.ResponseItems) {
            quote.ResponseItems.sort((a, b) => a.ItemID - b.ItemID);

            for (let i = 0; i < quote.ResponseItems.length; i++) {
                const quotes = jewelryItems[i].quotes;
                const selectedQuote = quotes.find(j => j.selected);
                assign(selectedQuote, selectedQuote, quote.ResponseItems[i]);
            }
        }

        commit('setRateQuote', quote);
        commit('setJewelryItems', jewelryItems);
    },

    clearRateQuote({ commit }) {
        commit('setRatQuote', null);
    },

    async loadRateQuoteById({ commit }, trackingId) {
        const { data } = await axios.get(`/api/quotes/${trackingId}`);

        if (!data.length || data.length !== 1) {
            throw new Error(`Unexpected response length ${data.length}`);
        }

        const quote = data[0];
        quote.ResponseItems.sort((a, b) => a.ItemID - b.ItemID);

        commit('setRateQuote', quote);
    },

    [action.SET_ACCOUNT]({ commit }, account) {
        commit('setAccount', account);
    },
    [action.SET_PAYMENT]({ commit }, payment) {
        commit('setPayment', payment);
    },

    async createAccountInGuidewire({ commit, getters }) {
        const request = constructAccountInformation({ getters });

        const { data } = await axios({
            url: '/api/policy/createAccountInGuidewire',
            method: 'POST',
            data: request
        });

        commit('setAccount', data);
        router.push('/payment');
    },

    async submitNewPolicy({ getters }) {
        const request = constructAccountInformation({ getters });
        request.accountNumber = getters.account.accountNumber;

        const { data } = await axios({
            url: '/api/policy/new',
            method: 'POST',
            data: request
        });

        data;
    },

    async loadSession({ commit }) {
        try {
            const { data } = await axios({
                url: '/api/session',
                withCredentials: true
            });
            if (data && data.session) {
                commit('setSession', JSON.parse(data.session));
            }
        } catch (e) {
            /* Ignore */
        }
    },

    async findSession({ commit }, { lastName, zipCode, email }) {
        const { data } = await axios({
            url: '/api/session/find',
            method: 'POST',
            data: { lastName, zipCode, email },
            withCredentials: true
        });
        if (data && data.state) {
            commit('setSession', JSON.parse(data.state));
        }
    },

    async saveSession({ commit, getters }, { firstName, lastName, email }) {
        const zipCode =
            _get(getters, 'applicantAndWearers.applicant.zipCode') || _get(getters, 'previewQuoteLocation.zipCode');

        let applicantAndWearers = getters.applicantAndWearers;
        let updateApplicant = false;
        if (!applicantAndWearers) {
            applicantAndWearers = {
                applicant: cloneDeep(classes.applicantForm),
                wearerJewelryItems: getters.jewelryItems.map(() => cloneDeep(classes.jewelryItemForm)),
                wearerCrime: cloneDeep(classes.wearerCrime),
                agreedTerms: false
            };
            updateApplicant = true;
        }
        if (!applicantAndWearers.applicant) {
            applicantAndWearers.applicant = cloneDeep(classes.applicantForm);
            updateApplicant = true;
        }
        if (!applicantAndWearers.applicant.firstName) {
            applicantAndWearers.applicant.firstName = firstName || '';
            updateApplicant = true;
        }
        if (!applicantAndWearers.applicant.lastName) {
            applicantAndWearers.applicant.lastName = lastName;
            updateApplicant = true;
        }
        if (!applicantAndWearers.applicant.email) {
            applicantAndWearers.applicant.emailAddress = email;
            updateApplicant = true;
        }
        if (!applicantAndWearers.applicant.zipCode) {
            applicantAndWearers.applicant.zipCode = zipCode;
            updateApplicant = true;
        }
        if (updateApplicant) {
            commit('setApplicantAndWearers', applicantAndWearers);
        }

        const state = {
            resultStep: getters.resultStep,
            previewQuoteLocation: getters.previewQuoteLocation,
            appraisal: getters.appraisal,
            jewelryItems: getters.jewelryItems,
            applicantAndWearers
        };

        await axios({
            url: '/api/session',
            method: 'POST',
            withCredentials: true,
            data: {
                lastName,
                email,
                zipCode,
                state: JSON.stringify(state)
            }
        });
    },
    async postSegmentData({ getters }, mode = 'save') {
        const applicant = getters.getApplicant;
        const appraisal = getters.appraisal;
        const order = getters.order;

        let leadItems = {};
        const totals = {};
        if (!getters.resultStep || getters.resultStep < constants.steps.review) {
            // Preview quotes
            totals.quote_total_value = 0;
            totals.quote_total_items = 0;
            totals.lead_quote_premium = getters.previewTotals.total;
            totals.lead_taxes = getters.previewTotals.taxesAndFees;

            getters.jewelryItems.forEach((item, index) => {
                const selectedQuote = item.quotes.find(q => q.selected) || item.quotes[0];
                const leadItemString = `lead_item_${index + 1}`;
                const leadItem = {
                    [leadItemString]: getJewelryType(selectedQuote.ItemID),
                    [`${leadItemString}_deductible`]: selectedQuote.Deductible,
                    [`${leadItemString}_premium`]: selectedQuote.AnnualPremium,
                    [`${leadItemString}_value`]: selectedQuote.ItemLimit
                };
                leadItems = { ...leadItems, ...leadItem };

                totals.quote_total_value += selectedQuote.ItemLimit;
                totals.quote_total_items += 1;
                if (!totals.quote_date) {
                    totals.quote_date = moment(selectedQuote.quoteDate).format('YYYY-MM-DD');
                    totals.quote_expiration_date = moment(selectedQuote.quoteDate)
                        .add(60, 'days')
                        .format('YYYY-MM-DD');
                }
            });
        } else {
            // Rate quote
            const quote = getters.rateQuote;
            const quoteItems = _get(quote, 'ResponseItems', []);

            totals.lead_quote_premium = getters.rateTotals.total;
            totals.lead_taxes = getters.rateTotals.taxesAndFees;
            totals.quote_total_items = quoteItems.length || undefined;
            totals.quote_total_value = quoteItems.map(item => item.ItemLimit).reduce((a, b) => a + b, 0) || undefined;
            totals.quote_date = moment(quote.quoteDate).format('YYYY-MM-DD');
            totals.quote_expiration_date = moment(quote.quoteDate)
                .add(60, 'days')
                .format('YYYY-MM-DD');

            quote.ResponseItems.forEach((item, index) => {
                const leadItemString = `lead_item_${index + 1}`;
                const leadItem = {
                    [leadItemString]: getJewelryType(item.ItemID),
                    [`${leadItemString}_deductible`]: item.Deductible,
                    [`${leadItemString}_premium`]: item.AnnualPremium,
                    [`${leadItemString}_value`]: item.ItemLimit
                };
                leadItems = { ...leadItems, ...leadItem };
            });
        }

        const appraisalNumber = _get(appraisal, 'appraisalNumber', undefined);
        let quoteUrl;
        if (appraisalNumber) {
            quoteUrl = `${window.location.origin}/appraisal/${appraisalNumber}`;
        }

        let jeweler_source = undefined;
        if (appraisal && appraisal.jewelerSource) {
            jeweler_source = appraisal.jewelerSource;
        } else if (order && order.jewelerSource) {
            jeweler_source = order.jewelerSource;
        }

        const payload = {
            ...totals,
            ...leadItems,
            mode,
            firstname: _get(applicant, 'firstName', undefined),
            lastname: _get(applicant, 'lastName', undefined),
            email: _get(applicant, 'emailAddress', undefined),
            state: _get(applicant, 'state', undefined),
            country: _get(applicant, 'country', undefined),
            lead_source: 'Appraisal',
            jeweler_source: jeweler_source,
            lead_source_producer: _get(appraisal, 'vendorName', undefined),
            zing_quote_url: quoteUrl,
            quote_last_saved_date: moment().format('YYYY-MM-DD'),
            saved_count: 1 // TODO
        };

        await axios.post('/api/session/segment', payload);
    }
};

function getJewelryType(id) {
    switch (id) {
        case JEWELRY_TYPES.RING_TYPE:
            return 'Ring';
        case JEWELRY_TYPES.EARRINGS_TYPE:
            return 'Earrings';
        case JEWELRY_TYPES.BRACELET_TYPE:
            return 'Bracelet';
        case JEWELRY_TYPES.NECKLACE_TYPE:
            return 'Necklace';
        case JEWELRY_TYPES.WATCH_TYPE:
            return 'Watch';
        case JEWELRY_TYPES.PENDANT_TYPE:
            return 'Pendant';
        case JEWELRY_TYPES.CHAIN_TYPE:
            return 'Chain';
        case JEWELRY_TYPES.LOOSE_STONE_TYPE:
            return 'LooseStone';
        case JEWELRY_TYPES.BROOCH_TYPE:
            return 'Brooch';
        default:
            return 'Other';
    }
}
