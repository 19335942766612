<template>
    <b-tooltip :placement="placement" :target="target">
        <div class="tooltip-content">
            {{ title }}
        </div>
    </b-tooltip>
</template>

<script>
export default {
    name: 'Tooltip',
    props: {
        target: {
            type: String,
            required: true
        },
        placement: {
            type: String,
            required: false,
            default: 'right',
            validator: val => ['top', 'bottom', 'left', 'right'].includes(val)
        },
        title: {
            type: String,
            required: true
        }
    }
};
</script>

<style scoped lang="scss">
/deep/ .arrow::before {
    border: 1px solid rgba(0, 0, 0, 0.2);
}

/deep/ .tooltip-inner {
    border: 1px solid rgba(0, 0, 0, 0.2);
    background-color: $white;
    padding: 9px 14px;
    color: $black;
    font-size: 14px;
}
</style>
