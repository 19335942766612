<template>
    <b-col class="container" cols="12" md="4">
        <div class="sticky-col">
            <slot />
        </div>
    </b-col>
</template>

<script>
export default {
    name: 'Right'
};
</script>

<style lang="scss" scoped>
.container {
    margin-bottom: $margin-under-element - 10px;

    .sticky-col {
        @media (min-width: 768px) {
            position: sticky;
            top: 15px;
        }
    }
}
</style>
