import 'core-js/stable';
import 'regenerator-runtime/runtime';
import Vue from 'vue';
import App from './App.vue';
import router from './router';
import store from './store';
import { BootstrapVue, IconsPlugin } from 'bootstrap-vue';
import vueNumeralFilterInstaller from 'vue-numeral-filter';

import './registerServiceWorker';
import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap-vue/dist/bootstrap-vue.css';
import './validationRules';

Vue.config.productionTip = false;

Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(vueNumeralFilterInstaller, { locale: 'en-gb' });

Vue.prototype.$eventBus = Vue.prototype.$eventBus || new Vue();

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.step)) {
        store.dispatch('setResultStep', to.meta.step).then(() => next());

        return;
    }

    next();
});

new Vue({
    router,
    store,
    render: h => h(App),
    // Enables auto-scrolling to the element (....#elementId).
    scrollBehavior(to, from, savedPosition) {
        // If user goes back and old uri that has a hash, that hash is saved in savedPosition parameter.
        if (savedPosition) {
            return savedPosition;
        }
        // Check if URI has any hash. To add a hash: {..., hash: 'tagId'}.
        if (to.hash) {
            return {
                selector: to.hash
            };
        }

        return {
            x: 0,
            y: 0
        };
    }
}).$mount('#app');
