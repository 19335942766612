<template>
    <div class="title-row">
        <h1 class="heading">
            {{ heading }}
        </h1>
        <h6>
            <slot />
        </h6>
    </div>
</template>

<script>
export default {
    name: 'Title',
    props: {
        heading: {
            type: String,
            required: true
        }
    }
};
</script>

<style lang="scss" scoped>
.title-row {
    margin-bottom: $margin-under-element;

    .heading {
        @media (max-width: 767px) {
            font-size: 32px;
            text-align: center;
        }
    }

    a {
        color: $blue;
    }
}
</style>
