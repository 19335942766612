import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import constants from '@/constants';

Vue.use(VueRouter);

const loadView = view => () => ({
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: import(`@/views/${view}.vue`),
    loading: '<h1>Loading...</h1>'
});

const isObject = object => object && typeof object === 'object';

const setRouter = (path, component, others) => ({
    path: `/${path}`,
    component: isObject(component) ? component : loadView(component),
    ...others
});

const { steps } = constants;

const router = new VueRouter({
    mode: 'history',
    base: process.env.BASE_URL,
    routes: [
        setRouter('', Home, { name: 'home', meta: { step: steps.initial, inFlow: true } }),
        setRouter('appraisal/:appraisalId', 'Appraisal', { meta: { inFlow: true } }),
        setRouter('look-up', 'AppraisalLookup', { meta: { inFlow: true } }),
        setRouter('in/:appraisalId', 'Appraisal', { meta: { inFlow: true } }),
        setRouter('point-of-sale/:orderId', 'Order', { meta: { inFlow: true } }),
        setRouter('retrieve', 'Retrieve', { meta: { inFlow: true } }),
        setRouter('faq', 'Faq'),
        setRouter('privacy-notice', 'PrivacyNotice'),
        setRouter('results', 'Results', {
            children: [
                setRouter('results', 'Quote', { meta: { step: steps.quote, inFlow: true } }),
                setRouter('applicant', 'ApplicantAndWearers', {
                    meta: { step: steps.applicantAndWearers, inFlow: true }
                }),
                setRouter('jewelry-details', 'JewelryDetails', {
                    meta: { step: steps.jewelryDetails, inFlow: true }
                }),
                setRouter('review', 'Review', {
                    meta: { step: steps.review, inFlow: true }
                })
            ]
        }),
        setRouter('payment', 'Payment', {
            meta: { step: steps.payment, inFlow: true }
        }),
        setRouter('confirmation', 'Confirmation', {
            meta: { step: steps.confirmation }
        }),
        setRouter('*', 'NotFound')
    ]
});

router.afterEach((to, from) => {
    if (to.fullPath !== from.fullPath && window.analytics) {
        // Send update to segment
        window.analytics.page();
    }
});

export default router;
