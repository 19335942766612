<template>
    <Title heading="Get Your Free Jewelry Insurance Quote">
        Already have a quote?&nbsp;
        <b-link to="/retrieve">Retrieve your saved quote or application</b-link>
    </Title>
</template>

<script>
import Title from '@/components/Common/Title';

export default {
    name: 'TitleForRetrieveLink',
    components: { Title }
};
</script>

<style scoped></style>
