<template>
    <b-button :type="type" :class="{ 'btn-cancel': !!cancel }" @click="$emit('clickEvent')" :disabled="disabled">
        {{ text }}
    </b-button>
</template>

<script>
export default {
    name: 'Submit',
    props: {
        type: {
            type: String,
            required: false,
            default: 'button',
            validator: val => ['button', 'submit'].includes(val)
        },
        text: {
            type: String,
            required: true,
            default: 'Submit'
        },
        cancel: {
            type: Boolean,
            required: false,
            default: false
        },
        disabled: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        isForSubmit: false
    }),
    mounted() {
        this.isForSubmit = this.text && this.text.toLowerCase() === 'submit';
    }
};
</script>

<style lang="scss" scoped></style>
