export const TYPES = {
    RING_TYPE: 1,
    EARRINGS_TYPE: 2,
    BRACELET_TYPE: 3,
    NECKLACE_TYPE: 4,
    WATCH_TYPE: 5,
    PENDANT_TYPE: 6,
    CHAIN_TYPE: 7,
    OTHER_TYPE: 8,
    LOOSE_STONE_TYPE: 9,
    BROOCH_TYPE: 10
};

export const SECURITY_OPTIONS = [
    { text: 'No', value: 1 },
    { text: 'Monitored Alarm System', value: 2 },
    { text: 'Local Alarm', value: 3 }
];
export const alarmTypeToEnum = id => {
    switch (id) {
        case 2:
            return 'Monitored';
        case 3:
            return 'Local';
        default:
            return 'None';
    }
};

export default [
    {
        text: 'Ring',
        value: 1,
        src: 'ring-b.png',
        selectedSrc: 'ring-w.png'
    },
    {
        text: 'Earrings',
        value: 2,
        src: 'earrings-b.png',
        selectedSrc: 'earrings-w.png'
    },
    {
        text: 'Bracelet',
        value: 3,
        src: 'bracelet-b.png',
        selectedSrc: 'bracelet-w.png'
    },
    {
        text: 'Necklace',
        value: 4,
        src: 'necklace-b.png',
        selectedSrc: 'necklace-w.png'
    },
    { text: 'Watch', value: 5 },
    {
        text: 'Pendant',
        value: 6,
        src: 'pendant-b.png',
        selectedSrc: 'pendant-w.png'
    },
    { text: 'Chain', value: 7 },
    { text: 'Other', value: 8 },
    { text: 'Loose stone', value: 9 },
    { text: 'Brooch', value: 10 }
];
