import Vue from 'vue';
import Vuex from 'vuex';
import getState from './state';
import getters from './getters';
import mutations from './mutations';
import actions from './actions';

import VuexPersistence from 'vuex-persist/dist/umd';

const vuexSession = new VuexPersistence({
    storage: window.sessionStorage
});

const resetMutation = {
    resetState(originalState) {
        // Merge rather than replace so we don't lose observers
        // https://github.com/vuejs/vuex/issues/1118
        Object.assign(originalState, getState());
    }
};

Vue.use(Vuex);

export default new Vuex.Store({
    state: getState(),
    getters,
    mutations: { ...mutations, ...resetMutation },
    actions,
    plugins: [vuexSession.plugin]
});
