<template>
    <b-form-group :description="description" :label-for="`form-input-${name}`" class="font-weight-bold" label-size="sm">
        <template v-slot:label>
            {{ title }}
            <template v-if="!!tooltip">
                <b-icon :id="`show-tooltip-${name}`" class="bootstrap-icon" icon="question-circle-fill" />
                <Tooltip :target="`show-tooltip-${name}`" :title="tooltip" />
            </template>
        </template>
        <b-form-select
            v-model="selected"
            :name="name"
            :options="options"
            :state="fieldState"
            :autocomplete="autocomplete"
        >
            <template v-slot:first>
                <b-form-select-option disabled value="">
                    {{ first || 'Select' }}
                </b-form-select-option>
            </template>
        </b-form-select>
        <b-form-invalid-feedback v-if="fieldState === false && errorLabel !== null" class="form-control-error">
            {{ errorLabel }}
        </b-form-invalid-feedback>
    </b-form-group>
</template>

<script>
import Tooltip from '@/components/Common/Tooltip';

export default {
    name: 'SelectInput',
    components: { Tooltip },
    props: {
        first: {
            type: String,
            required: false
        },
        name: {
            type: String,
            required: true
        },
        options: {
            type: Array,
            required: true,
            validator: prop =>
                prop.length > 0 &&
                prop.every(
                    p => typeof p.text === 'string' && (typeof p.value === 'string' || typeof p.value === 'number')
                )
        },
        model: {
            type: [String, Number],
            required: true,
            default: ''
        },
        required: {
            type: Boolean,
            required: false,
            default: false
        },
        title: {
            type: String,
            required: false,
            default: ''
        },
        placeholder: {
            type: String,
            required: false,
            default: ''
        },
        autocomplete: {
            type: String,
            required: false
        },
        description: {
            type: String,
            required: false,
            default: ''
        },
        tooltip: {
            type: String,
            required: false,
            default: ''
        },
        errorLabel: {
            type: String,
            required: false,
            default: 'This field is required'
        },
        validator: {
            type: Function,
            required: false,
            default: () => true
        },
        showError: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data: () => ({
        selected: ''
    }),
    computed: {
        isValid() {
            return !!this.validator(this.selected);
        },
        fieldState() {
            return !this.showError || this.isValid ? null : false;
        }
    },
    mounted() {
        this.selected = this.model;
    },
    watch: {
        selected(val) {
            this.$emit('inputChanged', val);
            this.$emit('validate', this.isValid);
        },
        model(val) {
            this.selected = val;
        }
    }
};
</script>
<style lang="scss" scoped>
.custom-select {
    border-radius: 0;

    &.is-invalid {
        border-color: #f04033;
        background: #ffe1e4
            url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' width='4' height='5' viewBox='0 0 4 5'%3e%3cpath fill='%23343a40' d='M2 0L0 2h4zm0 5L0 3h4z'/%3e%3c/svg%3e")
            no-repeat right 0.75rem center/8px 10px;

        &:focus {
            background-color: #ffffff;
        }
    }
}
</style>
