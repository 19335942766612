export default {
    firstName: '',
    lastName: '',
    isMailingAddress: true,
    mailingAddress: {
        address: '',
        apartmentSuite: '',
        city: '',
        state: '',
        zipCode: '',
        county: '',
        country: ''
    },
    phoneNumber: '',
    emailAddress: '',
    dateOfBirth: '',
    relationshipToApplicant: '',
    gender: ''
};
