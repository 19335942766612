export default {
    isMailingAddress: true,
    firstName: '',
    lastName: '',
    address: '',
    apartmentSuite: '',
    city: '',
    state: '',
    zipCode: '',
    county: '',
    country: '',
    phoneNumber: '',
    emailAddress: '',
    dateOfBirth: '',
    gender: '',
    mailingAddress: {
        address: '',
        apartmentSuite: '',
        city: '',
        state: '',
        zipCode: '',
        county: '',
        country: ''
    },
    security: '',
    effectiveDate: '',
    isPaperlessEnrolled: false
};
