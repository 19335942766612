import constants from '@/constants';

const shuffleArray = array => {
    if (Array.isArray(array)) {
        // Returns a new Buffer that references the same memory as the original aka copies the array.
        const newArray = array.slice();

        // Loops newArray to shuffle indexes by using Durstenfeld shuffle algorithm.
        for (let i = newArray.length - 1; i > 0; i--) {
            const rand = Math.floor(Math.random() * (i + 1));

            // An ES6 one line code for swapping the value of two indexes.
            [newArray[i], newArray[rand]] = [newArray[rand], newArray[i]];
        }

        return newArray;
    }

    return array;
};

const getJewelryNamesFilterByText = (text = '') => {
    let { jewelryItems } = constants;

    jewelryItems = jewelryItems
        .filter(jewelry => jewelry.text !== 'Other' && jewelry.text !== text)
        .map(item => item.text);

    return jewelryItems;
};

const xTimes = x => func => {
    // Checks if counter is bigger than zero to keep calling func recursively.
    if (x > 0) {
        func();
        xTimes(x - 1)(func);
    }
};

const randomIndexFromArray = array => (Array.isArray(array) ? Math.floor(Math.random() * array.length) : 0);

// Generates a sentence that includes jewelries N times and notIncludedJewelry will not be in the sentence.
const getJewelryNamesWithSentence = notIncludedJewelry => {
    let foundJewelries = [];
    const names = shuffleArray(getJewelryNamesFilterByText(notIncludedJewelry));
    let sentence = '';

    const pushToNewArray = () => {
        const index = randomIndexFromArray(names);

        foundJewelries.push(names[index]);
    };

    xTimes(2)(() => {
        pushToNewArray();
        const dupFoundJewelries = new Set(foundJewelries);

        if (foundJewelries.length !== dupFoundJewelries.size) {
            foundJewelries = Array.from(dupFoundJewelries);

            pushToNewArray();
        }
    });

    foundJewelries.forEach((i, k, a) => {
        sentence += `<b>${i}</b>${k !== a.length - 1 ? ' or ' : '.'}`;
    });

    return sentence;
};

const isDate = stringDate => !isNaN(new Date(stringDate).getDate());
const exists = prop => typeof prop !== 'undefined';
const deepCopy = immutableObject => JSON.parse(JSON.stringify(immutableObject));

export const scrollToError = async () => {
    try {
        const firstErrorElement = document.querySelector('.is-invalid');
        if (firstErrorElement) {
            firstErrorElement.scrollIntoView({
                behavior: 'smooth',
                block: 'center'
            });
        }
    } catch (e) {
        /* Ignore */
    }
};

export default {
    getJewelryNamesWithSentence,
    isDate,
    exists,
    deepCopy,
    scrollToError
};
