<template>
    <div id="app">
        <b-container fluid="md" class="main">
            <Header />
            <router-view :key="$route.fullPath" />
        </b-container>
        <Footer />
    </div>
</template>
<script>
import Header from './components/Layout/Header';
import Footer from './components/Layout/Footer';
import { mapActions } from 'vuex';

export default {
    components: { Footer, Header },
    mounted() {
        this.loadSession();
    },
    methods: {
        ...mapActions(['loadSession'])
    }
};
</script>
<style scoped lang="scss">
.main {
    min-height: calc(100vh - 209px);
}
</style>
